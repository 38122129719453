html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.sun {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.sky {
  text-align: center;
  width: 60%!important;
  margin: 0 auto;
}

.centerText {
  padding-top: 15px;
  vertical-align: baseline;
  margin: 0 auto;
  text-align: center;
}

.powerValue {
  width: 350px;
  margin: 0 auto;
}

@media(max-width: 480px){
  .powerDisplay {
    padding-top: 150px!important;
  }
}

.isoffline {
  position: absolute;
  display: inline-block;
  margin: 2px 50px;
  font-size: 10px;
  color: rgb(179, 180, 81);
  text-decoration: line-through;
}

.isonline {
  position: absolute;
  display: inline-block;
  margin: 2px 50px;
  font-size: 10px;
  
   /* Chrome, Safari, Opera */
  -webkit-animation: PULSE 1s infinite; 
  
  /* Internet Explorer */
  -ms-animation: PULSE 1s infinite;
  
  /* Standard Syntax */
  animation: PULSE 1.25s infinite; 
  font-weight: bold;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes PULSE{
   0%{color:rgb(255, 0, 0);}	
	110%{color: black;}
}

/* Internet Explorer */
@-ms-keyframes PULSE{
  0%{color:rgb(255, 0, 0);}	
	110%{color: black;}
}

/* Standard Syntax */
@keyframes PULSE{
  0%{color:rgb(255, 0, 0);}	
	110%{color: rgb(0, 0, 0);}
}

/* .pulsate { 
  -webkit-animation: pulsate 3s ease-out;
  -webkit-animation-iteration-count: infinite; 
  opacity: 0.5;
}
@-webkit-keyframes pulsate {
  0% { 
      opacity: 0.5;
  }
  50% { 
      opacity: 1.0;
  }
  100% { 
      opacity: 0.5;
  }
} */

.recharts-wrapper {
  margin: 0 auto;
}

